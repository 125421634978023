<template>
  <div class="d-flex min-vh-100">
    <!-- Left Text-->
    <div
      class="d-none d-lg-block col-6 px-0"
    >
      <div class="left-side">
        <div class="left-side__content">
          <div class="left-side__logo">
            <img
              src="../../assets/images/logo/jaky-logo.svg"
              alt="логотип"
            >
          </div>
          <div class="left-side__slogan">
            Ваш мастер автосервиса
          </div>
        </div>
        <div class="large-circle top-right-corner" />
        <div class="small-circle bottom-left-corner" />
      </div>
    </div>
    <!-- /Left Text-->

    <!-- Login-->
    <div
      class="col-12 col-lg-6 px-2 p-lg-5"
    >
      <div class="right-side">
        <router-view />
      </div>
    </div>
    <!-- /Login-->
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
